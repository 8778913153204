import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '22, 163, 170',
		'primary-dark': '51, 51, 51',
		'accent': '255, 230, 177',
		'accent-plus': '0, 0, 0',
	},
});
